import { css } from '@emotion/react'

import { Link } from '@remix-run/react'
import { mTheme } from '../../../style/themes'
import { createEventShareData } from '../../../utilities/eventSharing'
import { SaveEventButtonForCardFooter } from '../../SaveEventButton/SaveEventButton'
import ShareButton from '../../ShareButton'

import * as styles from './EventFooterButtons.styles'
import { getEventTicket } from '../../../features/tickets/getEventTicket'
import { useEventTicketsUrl } from '../../../features/tickets/useEventTicketPath'
import { EventDetailsFragment } from '../../../graphql/fragments/eventDetails.fragment.gen'
import { useConfig } from '../../../utilities/config-context'

type EventFooterButtonsProps = {
  event: EventDetailsFragment
}

let primaryStyle: Style = css({
  ':visited': {
    color: mTheme.color.primary,
  },
  color: mTheme.color.primary,
})

let EventFooterButtons: React.FC<EventFooterButtonsProps> = ({ event }) => {
  let ticketsLinkTo = useEventTicketsUrl(event)
  let ticket = getEventTicket(event)
  let config = useConfig()
  let sharingData = createEventShareData(event, config)

  return (
    <footer css={styles.footer}>
      <SaveEventButtonForCardFooter {...event} />

      {ticket && (
        <Link
          to={ticketsLinkTo}
          css={[styles.footerButton, primaryStyle]}
          prefetch="viewport"
        >
          <span>Buy</span>
          <span>
            <i css={styles.icon} className="fas fa-ticket-alt" />
            {ticket.price.amount > 0 ? `£${ticket.price.amount}` : 'Free'}
          </span>
        </Link>
      )}

      <ShareButton
        plain
        style={styles.footerButton}
        {...sharingData}
        // Send to tickets page to track which event participant made ticket sales
        // TODO: refactor sharing data to reusable hook
        url={ticket ? ticketsLinkTo : sharingData.url}
      />
    </footer>
  )
}

export default EventFooterButtons
